

export const sliderSettings = {
    slidesPerView: 3,
    spaceBetween: 50,
    breakpoints: {
        480: {
            slidesPerView: 1,
            slidesPerGroup: 1
        },
        600: {
            slidesPerView: 2,
            slidesPerGroup: 2
        },
        750: {
            slidesPerView: 3,
            slidesPerGroup: 3
        },
        1100: {
            slidesPerView: 4,
            slidesPerGroup: 4
        }
    }
}









































