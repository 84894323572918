

import './Hero.css'
import {HiLocationMarker} from "react-icons/hi";
import CountUp from "react-countup";
import { motion } from "framer-motion";

const Hero = () => {

    return (
        <section className="hero-wrapper">
            <section className="padding innerWidth flexCenter hero-container">


                <div className="flexColStart hero-left">
                    <div className="hero-title">
                        <div className="orange-circle" />
                        <motion.h1
                            initial={{y: "2rem", opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{
                                duration: 2,
                                type: "spring"
                            }}
                        >
                            Discover <br />
                            Most suitable <br />
                            Property
                        </motion.h1>
                    </div>

                    <div className="flexColStart hero-des">
                        <span className="secondaryText">Find a a propertyFind a propertyFind a propertytyFind a propertyFind a property</span>
                        <span className="secondaryText">Welcome the websiteWelcome to the witeWelcome to the website</span>
                    </div>

                    <div className="flexCenter search-bar">
                        <HiLocationMarker color="var(--blue)" size={25} />
                        <input type="text"/>
                        <button className="button">Search</button>
                    </div>

                    <div className="flexCenter stats">
                        <div className="flexColCenter stat">
                            <span>
                                <CountUp start={8000} end={9000} duration={20} />
                                <span>+</span>
                            </span>
                            <span className="secondaryText">
                                Premium products
                            </span>
                        </div>

                        <div className="flexColCenter stat">
                            <span>
                                <CountUp start={100} end={500} duration={20} />
                                <span>+</span>
                            </span>
                            <span className="secondaryText">
                                Happy customers
                            </span>
                        </div>

                        <div className="flexColCenter stat">
                            <span>
                                <CountUp start={20} />
                                <span>+</span>
                            </span>
                            <span className="secondaryText">
                                Award Winning
                            </span>
                        </div>

                    </div>

                </div>


                <motion.div
                    className="flexCenter hero-right"
                    initial={{x: "7rem", opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                >
                    <div className="image-container">
                        <img src="./hero-image.png" alt="hero image"/>
                    </div>
                </motion.div>
            </section>
        </section>
    )



}


export default Hero



















