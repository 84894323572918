
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemState,
    AccordionItemPanel,
    AccordionItemButton
} from "react-accessible-accordion";

import 'react-accessible-accordion/dist/fancy-example.css'
import { MdOutlineArrowDropDown } from "react-icons/md";
import './Value.css'
import data from '../../utils/accordion'
import {useState} from "react";


const Value = () => {
    return (
        <section className="v-wrapper">
            <div className="paddings innerWidth flexCenter v-container">
                <div className="v-left">
                    <div className="image-container">
                        <img src="./value.png" alt=""/>
                    </div>
                </div>

                <div className="flexColStart v-right">
                    <span className="orangeText">Our Value</span>
                    <span className="primaryText">Value We Give to You</span>
                    <span className="secondaryText">
                        We are always ready to help you by providing the best services for you.
                        <br />
                        We believe a good balance to live can make your life better
                    </span>

                    <Accordion
                        className="accordion"
                        allowMultipleExpanded={false}
                        preExpanded={[0]}
                    >
                        {data.map((item, i) => {
                                return (
                                    <CustomItem key={i} item={item} />
                                )
                            })
                        }
                    </Accordion>

                </div>
            </div>
        </section>
    )
}

const CustomItem = ({ item, key }) => {
    const i = key
    const [className, setClassName] = useState(null)
    return (
        <AccordionItem className={`accordionItem ${className}`} key={i} uuid={i}>
            <AccordionItemHeading>
                <AccordionItemButton className="flexCenter accordionButton">

                    <AccordionItemState>
                        {({expanded}) => expanded ? setClassName("expanded") : setClassName("collapsed")}
                    </AccordionItemState>

                    <div className="flexCenter icon">{item.icon}</div>
                    <span className="primaryText">{item.heading}</span>
                    <div className="flexCenter icon">
                        <MdOutlineArrowDropDown size={20}/>
                    </div>
                </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
                <p className="secondaryText">{item.detail}</p>
            </AccordionItemPanel>
        </AccordionItem>
    )
}

export default Value

































